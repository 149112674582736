<template>
  <div>
    <b-modal
      id="modal-bet-history"
      hide-footer
      centered
      :title="$t('general.betHistory')"
      size="xl"
      @hide="isShow = false"
    >
      <!-- Search Container Card -->
      <n-search-container
        :active="true"
        :show-header="false"
        @search="search"
        @reset="reset"
        v-if="searchFields.length"
        :loading="loading"
      >
        <n-search-input ref="search" :fields="searchFields" v-model="params">
          <template #period="item">
            <b-form-group
              :label-for="item.field.key"
              :label="$t(item.field.label)"
            >
              <b-form-radio-group
                :ref="item.field.key"
                v-if="item.field.type === 'radio'"
                :id="item.field.key"
                v-model="params[item.field.key]"
                buttons
                button-variant="outline-primary rounded-0"
                class="flex-wrap"
              >
                <b-form-radio
                  :value="option.value"
                  v-for="option in item.field.options"
                  :key="option.value"
                  >{{ $t(option.text) }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </template>
        </n-search-input>
      </n-search-container>

      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <div class="m-0-5">
          <b-row>
            <b-col>
              <label>{{ $t("general.entries") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <n-table
            @sort-changed="sortChanged"
            :fields="fields"
            :items="items"
            :busy="loading"
            :current-page="params.page"
            :per-page="$store.state.pagination.perPage"
            :total="total"
          >
            <template #cell(channelId)="data">
              {{ data.item.channelName }}
            </template>
            <template #cell(createdAt)="data">
              {{ data.item.createdAt | formatDate }}
            </template>
            <template #cell(no)="data">
              <span v-if="data.item.betType == 'parley'">
                {{ data.item.gameType }}
              </span>
              <span v-else>{{ "#" + data.item.no }}</span>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount | currency(data.item.ccy) }}
            </template>
            <template #cell(selectedBet)="data">
              <b-link
                class="link-underline"
                @click="showParleyDetail(data.item)"
                v-if="data.item.betType == 'parley'"
              >
                {{ data.item.type }}
              </b-link>
              <span
                v-else
                :class="'text-capitalize text-' + data.item.selectedBet"
              >
                <span>
                  {{ data.item.selectedBet }}
                </span>
              </span>
            </template>
            <template #cell(matchResult)="data">
              <span
                class="text-capitalize"
                v-if="data.item.betType == 'parley'"
              >
                <span
                  class="text-capitalize text-draw"
                  v-if="data.item.parleyResult == 1"
                >
                  {{ $t("general.win") }}
                </span>
                <span
                  class="text-capitalize text-meron"
                  v-if="data.item.parleyResult == 0"
                >
                  {{ $t("general.loss") }}
                </span>
              </span>
              <span
                v-if="data.item.matchResult"
                :class="'text-capitalize text-' + data.item.matchResult"
              >
                <span>
                  {{ data.item.matchResult }}
                </span>
              </span>
            </template>
            <template #cell(winAmount)="data">
              <span
                :class="{
                  'text-wala': data.item.winAmount >= 0,
                  'text-meron': data.item.winAmount < 0,
                }"
              >
                {{ data.item.winAmount | currency(data.item.ccy) }}
              </span>
            </template>
            <template #cell(paybackAmount)="data">
              <span
                :class="{
                  'text-wala': data.item.paybackAmount >= 0,
                  'text-meron': data.item.paybackAmount < 0,
                }"
              >
                {{ data.item.paybackAmount | currency(data.item.ccy) }}
              </span>
            </template>
            <template #custom-foot>
              <b-tr v-if="!loading">
                <b-th colspan="4"></b-th>
                <b-th class="text-center">
                  <h5 class="font-weight-bold">
                    {{ totalReport.totalBetAmount | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th></b-th>
                <b-th class="text-center">
                  <h5 class="font-weight-bold">{{ total }}</h5>
                </b-th>
                <b-th></b-th>
                <b-th class="text-center">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-wala': totalReport.totalWinAmount >= 0,
                      'text-meron': totalReport.totalWinAmount < 0,
                    }"
                  >
                    {{ totalReport.totalWinAmount | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th class="text-center">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-wala': totalReport.totalPaybackAmount >= 0,
                      'text-meron': totalReport.totalPaybackAmount < 0,
                    }"
                  >
                    {{
                      totalReport.totalPaybackAmount | currency(totalReport.ccy)
                    }}
                  </h5>
                </b-th>
              </b-tr>
            </template>
          </n-table>
          <n-pagination
            ref="jackpotHistoryPagination"
            class="mt-2"
            :total="total"
            :per-page="$store.state.pagination.perPage"
            :page="params.page"
            @change="list"
          ></n-pagination>
        </div>
      </b-card>
    </b-modal>
    <parley-report-detail
      ref="parleyReportHistoryDetail"
      reportId="parleyReportHistoryDetail"
    ></parley-report-detail>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BTr,
  BTh,
  BModal,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import ParleyReportDetail from "@/views/parleyReport/parleyDetail/Index";
import tableFields from "./tableField";
import searchInputs from "./searchInput";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import moment from "moment";

const BetHistoryRepository = Repository.get("betHistory");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BTr,
    BTh,
    BModal,
    BLink,
    ParleyReportDetail,
  },
  watch: {
    perPage(value) {
      this.list();
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.toDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      totalReport: {},
      params: {
        channelType: 1,
        page: 1,
        order: "",
        sort: "",
        userId: "",
        startDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        period: "today",
      },
      totalReport: {},
    };
  },
  methods: {
    show(params) {
      this.$bvModal.show("modal-bet-history");
      this.isShow = true;
      this.params = {
        channelType: 1,
        channelId: params.channelId,
        ...params,
      };
      this.list();
    },
    hide() {
      this.isShow = false;
      this.$bvModal.hide("modal-bet-history");
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.getData();
    },
    search(searchText) {
      this.params.page = 1;
      this.params.search = searchText;
      this.$refs.jackpotHistoryPagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.toDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.$refs.jackpotHistoryPagination.reset();
      this.getData();
    },
    getData() {
      this.loading = true;
      BetHistoryRepository.index({
        ...this.params,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.totalReport = {};
          this.loading = false;
          this.getTotal();
          this.$refs.jackpotHistoryPagination.setPaginationLabel();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      BetHistoryRepository.total({
        ...this.params,
      }).then((response) => {
        let data = response.data.data;
        this.totalReport = {
          ...data,
        };
      });
    },
    showParleyDetail(data) {
      this.$refs.parleyReportHistoryDetail.show(data);
    },
  },
  setup() {
    const fields = [...tableFields];
    const searchFields = [...searchInputs];
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
